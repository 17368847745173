import React from "react";
import { Route, Switch, withRouter } from "react-router";
import Loading from "./Loading/Loading";
import Loadable from "react-loadable";
import Error from "./Error/Error";
import * as ReactGA from "react-ga";
import LoadingTrek from "./LoadingTrek/LoadingTrek";

const LoadableTradeIn = Loadable({
  loader: () => import("./tradeIn/TradeIn"),
  loading: Loading
});

const SmallWidget = Loadable({
  loader: () => import("./SmallWidget/SmallWidget"),
  loading: Loading
});

const LoadableTradeInTrek = Loadable({
  loader: () => import("./TradeInTrek/TradeInTrek"),
  loading: Loading
});

const LoadableTradeInCredit = Loadable({
  loader: () => import("./TradeInCredit/TradeInCredit"),
  loading: Loading
});
const LoadableDemoWidget = Loadable({
  loader: () => import("./demoWidget/DemoWidget"),
  loading: Loading
});
class AppRouter extends React.PureComponent {
  componentDidMount() {
    ReactGA.pageview(this.props.location.pathname + this.props.location.search);
  }

  render() {
    return (
      <Switch>
        <Route
          component={LoadableTradeInCredit}
          path={"/trade-in-credit"}
          exact={true}
        />
        <Route
          component={LoadableTradeInTrek}
          path={"/trade-in-trek-widget/:token"}
          exact={true}
        />
        <Route
          component={LoadableDemoWidget}
          path={"/demo-widget/:token"}
          exact={true}
        />
        <Route
          component={LoadableTradeIn}
          path={"/trade-in-widget/:token"}
          exact={true}
        />
        <Route path="/small-trade-in-widget/:token" component={SmallWidget} />
        <Route path="/loading-trek" component={LoadingTrek} />
        <Route path="/loading" component={Loading} />
        <Route path="/error" component={Error} />
        <Route
          path="*"
          component={() => (
            <div style={{ textAlign: "center", lineHeight: "400px" }}>
              What are you looking for?{" "}
              <a href={"/testScript.html"}>Test script</a> page?
            </div>
          )}
        />
      </Switch>
    );
  }
}

export default withRouter(AppRouter);
