import React, { Component } from "react";

class LoadingTrek extends Component {
  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <img
          src={require("./../../assets/images/loading-trek-max.gif")}
          alt=""
        />
      </div>
    );
  }
}

export default LoadingTrek;
