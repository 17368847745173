import React, { Component } from "react";
import "./Error.css";
class Error extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageError: ""
    };
  }
  componentDidMount() {
    if (this.props.match && this.props.match.params) {
      let params = this.props.match.params;
      this.setState({
        messageError: params.message
      });
    }
  }

  render() {
    return (
      <div className="container-message-error">
        The Value-Guide widget is currently unavailable or has been disabled by
        the partner. <br /> Contact{" "}
        <a
          href="https://www.bicyclebluebook.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          BicycleBlueBook.com
        </a>{" "}
        for support.
      </div>
    );
  }
}

export default Error;
