import React from 'react';
import AppRouter from './screens/AppRouter';
import ReactResizeDetector from 'react-resize-detector';
import { HashRouter } from 'react-router-dom';

class App extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      const initialHeight = document.getElementById('root').offsetHeight;
      window.parent.postMessage(
        { widgetData: 'height', height: initialHeight },
        '*'
      );
    }, 500);
  }

  onResize(width, height) {    
    window.parent.postMessage({ widgetData: 'height', height: height }, '*');
  }

  render() {
    return (
      <div>
        <ReactResizeDetector
          handleHeight
          onResize={this.onResize}
          querySelector={'#root'}
        />
        <HashRouter>
          <AppRouter/>
        </HashRouter>
      </div>
    );
  }
}

export default App;
