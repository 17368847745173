import {
  CONFIG_DEV
} from './config.dev';
import {
  CONFIG_PRODUCTION
} from './config.production';
import { CONFIG_STAGING } from './config.staging';

let CONFIG = CONFIG_DEV;
if (process.env.APP_ENV === 'dev') {
  Object.assign(CONFIG, CONFIG_DEV);
} else if (process.env.APP_ENV === 'staging') {
  Object.assign(CONFIG, CONFIG_STAGING);
} else {
  Object.assign(CONFIG, CONFIG_PRODUCTION);
}

export default CONFIG;
